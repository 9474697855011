import * as React from "react";
import { graphql } from 'gatsby';
import { buildSections } from "../services/sections";
import {Seo} from "../components/seo/seo";

const IndexPage = ({data}) => {

  const breadcrumbPath = [{title:'Localiser une agence'}];
  const sections = data.cms.pages.data[0].attributes.sections;
  const pageData = data.cms.pages.data[0].attributes;

  return (
    <div className="container-fluid">
      <Seo pageContext={{}} pageData={pageData} />
      {
        buildSections(sections, null, null, null, breadcrumbPath, -1)
      }
    </div>
  )
}

export default IndexPage;

export const query = graphql`
  query homePageQuery {
    cms {
      pages(filters: { name: { eq: "storelocator/home" }}) {
        data {
          attributes {
            seoMetaDescription
            seoMetaTitle
            sections {
              __typename
              ... on CMS_ComponentStoreLocatorSectionTexteImage {
                id
                bouton {
                  titre
                  style
                  url
                  action
                }
                slide {
                  id
                  tag
                  texte
                  titre
                }
                image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on CMS_ComponentStoreLocatorSectionArticles {
                id
                bouton {
                  style
                  titre
                  url
                  action
                }
                titre
                nombreArticles
                sousTitre
                typeArticle
              }
              ... on CMS_ComponentStoreLocatorSectionCarteAgences {
                id
              }
            }
          }
        }
      }
    }
  }
`;
